import React from 'react';
import { useHistory } from 'react-router-dom';
import Back from '../common/Back';
import { Button } from '@material-tailwind/react';
import { Typography } from '@material-tailwind/react';
import img1 from '../articles/NFT - Non Fungibel Token/Picture2.jpg';
import img from '../images/image/7.1.jpg';
import img2 from '../articles/Space-Tech/Picture2.jpg';
import img3 from '../articles/Genetic Discrimination/picture1.png';
import img4 from '../articles/White Collar Crimes/Picture2.jpg';
import img5 from '../articles/Environmental Law/Picture1.jpg';

const Articles = () => {
  const history = useHistory();

  const handleNFTClick = () => {
    history.push('/NFT'); // Navigate to the NFT page
  };

  const handleSpaceTechClick = () => {
    history.push('/spacetech'); // Navigate to the SpaceTech page
  };

  const handleGeneticClick = () => {
    history.push('/Genetic'); // Navigate to the Genetic Discrimination page
  };

  const handleWhitecollarClick = () => {
    history.push('/Whitecollar'); // Navigate to the White Collar page
  };

  const handleEnvironmentalLawClick = () => {
    history.push('/EnvironmentalLaw'); // Navigate to the White Collar page
  };

  return (
    <>
      <section className="about text-4xl">
        <Back name="Articles" title="" cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          {/* NFT Article Section */}
          <div className="md:flex md:justify-between md:items-center mb-12">
            <div className="md:w-1/3 md:flex md:justify-center">
              <img
                src={img1}
                alt="NFT"
                className="h-auto w-full md:max-h-80 md:w-full overflow-hidden object-cover"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </div>
            <div className="md:pl-8 md:w-2/3">
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                NFT's
              </Typography>
              <Typography variant="h5" className="text-gray-600">
                Non - Fungible Tokens
              </Typography>
              <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Non-Fungible Tokens or NFT’s, in short, have been gaining a lot of traction in recent times with the advent of Metaverse. Non-Fungible in terms of NFTs simply means something that is not directly changeable or something that can be replaced. Every NFT that is in existence is unique and cannot be replaced with a similar unit. A completely opposite example of Non-Fungible item is a currency note, every currency note may be unique, but can be replaced with something of the same value and its uniqueness (with regard to its serial number) has nothing to do with its value.
              </Typography>
              <div className="mt-8 mb-4 flex w-full items-center gap-3 md:w-1/2">
                <Button color="gray" className="w-52" onClick={handleNFTClick}>
                  Read More...
                </Button>
              </div>
            </div>
          </div>

          {/* Space Tech Article Section */}
          <div className="md:flex md:justify-between md:items-center">
            <div className="md:w-1/3 md:flex md:justify-center">
              <img
                src={img2}
                alt="Space Tech"
                className="h-auto w-full md:max-h-80 md:w-full overflow-hidden object-cover"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </div>
            <div className="md:pl-8 md:w-2/3">
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Need for Space-Tech Laws in India
              </Typography>
              <Typography variant="h5" className="text-gray-600">
                
              </Typography>
              <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                India is the birthplace of some famed astronauts and aerospace scientists. Every citizen of this country is proud of its satellite launches and all other space programs, including our latest achievement of moon landing. Despite all the accomplishments and a budget running in crores of rupees, India still lags behind many developed countries in the space tech industry. India has so far ratified four international treaties relating to outer space with the United Nations, five treaties with other nations/ space agencies and is a signatory to the Moon Agreement with the United Nations.
              </Typography>
              <div className="mt-8 mb-4 flex w-full items-center gap-3 md:w-1/2">
                <Button color="gray" className="w-52" onClick={handleSpaceTechClick}>
                  Read More...
                </Button>
              </div>
            </div>
          </div>

          {/* Genetic Discrimination Article Section */}
          <div className="md:flex md:justify-between md:items-center">
            <div className="md:w-1/3 md:flex md:justify-center">
              <img
                src={img3}
                alt="Genetic Discrimination"
                className="h-auto w-full md:max-h-80 md:w-full overflow-hidden object-cover"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </div>
            <div className="md:pl-8 md:w-2/3">
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Genetic Discrimination & Privacy of Genetic Data
              </Typography>
              <Typography variant="h5" className="text-gray-600">
                
              </Typography>
              <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Discrimination on the basis of medical condition which are present due to genetics by insurance companies has been a heated topic of discussion for quite some time now. This sort of discrimination is a clear violation of Article 14 of Constitution of India i.e. Right to Equality amongst all the citizens. 
              </Typography>
              <div className="mt-8 mb-4 flex w-full items-center gap-3 md:w-1/2">
                <Button color="gray" className="w-52" onClick={handleGeneticClick}>
                  Read More...
                </Button>
              </div>
            </div>
          </div>

          {/* White Collar Crimes Article Section */}
          <div className="md:flex md:justify-between md:items-center">
            <div className="md:w-1/3 md:flex md:justify-center">
              <img
                src={img4}
                alt="White Collar Crimes"
                className="h-auto w-full md:max-h-80 md:w-full overflow-hidden object-cover"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </div>
            <div className="md:pl-8 md:w-2/3">
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                White Collar Crimes
              </Typography>
              <Typography variant="h5" className="text-gray-600">
                
              </Typography>
              <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Crimes are the acts which are against the whole society at large but most of the criminal activities affect the people who are financially poor or incapable to protect themselves, now question here arises is the rich class more protected than the poor class. Answer to this question can be very tangled because crimes related to money can also happen to the section of the society which is financially sound. We analyse how white collar crimes came into existence or were they already existing but all these years, thinkers confused them with fraud. 
              </Typography>
              <div className="mt-8 mb-4 flex w-full items-center gap-3 md:w-1/2">
                <Button color="gray" className="w-52" onClick={handleWhitecollarClick}>
                  Read More...
                </Button>
              </div>
            </div>
            </div>
        {/* Principles governing Environmental Law Article Section */}
          <div className="md:flex md:justify-between md:items-center">
            <div className="md:w-1/3 md:flex md:justify-center">
              <img
                src={img5}
                alt="Principles Governing International Environmental Law"
                className="h-auto w-full md:max-h-80 md:w-full overflow-hidden object-cover"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', objectFit: 'contain' }}
              />
            </div>
            <div className="md:pl-8 md:w-2/3">
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Principles Governing International Environmental Law
              </Typography>
              <Typography variant="h5" className="text-gray-600">
                
              </Typography>
              <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              A detailed study on the Principles governing the Environmental Law. 
              </Typography>
              <div className="mt-8 mb-4 flex w-full items-center gap-3 md:w-1/2">
                <Button color="gray" className="w-52" onClick={handleEnvironmentalLawClick}>
                  Read More...
                </Button>
              </div>
            </div>
          </div>          
        </div>
      </section>
    </>
  );
};

export default Articles;
