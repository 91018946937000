import React, { useState, useEffect } from "react";
import Header from "../common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../home/Home";
import Footer from "../common/footer/Footer";
import About from "../about/About";
import Blog from "../blog/Blog";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import Modal from "react-modal";
import "./style.css";
import Civil from "../civil/Civil";
import Criminal from "../criminal/Criminal";
import BankingFinance from "../banking-finance/BankingFinance";
import IntellectualPropertyRights from "../intellectual-property-rights/IntellectualPropertyRights";
import ConstitutionalLaw from "../constitutional-law/ConstitutionalLaw";
import ServiceLaw from "../service-law/ServiceLaw";
import AlternateDisputeResolution from "../alternate-dispute-resolution/AlternateDisputeResolution";
import CoreTeam from "../coreTeam/CoreTeam";
import Corporate from "../corporate/Corporate";
import Newsltr from "../newsltr/Newsltr";
import Articles from "../articles/Articles";
import NFT from "../articles/NFT - Non Fungibel Token/NFT";
import spacetech from "../articles/Space-Tech/spacetech";
import DataPrivacy from "../data-privacy/DataPrivacy";
import Genetic from "../articles/Genetic Discrimination/genetic-discrimination";
import Whitecollar from "../articles/White Collar Crimes/white-collar-crimes";
import EnvironmentalLaw from "../articles/Environmental Law/EnvironmentalLaw";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";


function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
Modal.setAppElement("#root");

const Pages = () => {
  // const handleDisagreeClick = () => {
  //   // Reload the website
  //   window.location.reload();
  // };
  useEffect(() => {
    setOpen(true);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState(null);
  const handleOpen = () => setOpen(!open);
  const handleOpen2 = (value) => setSize(value);

  return (
    <div className="pages-container">
      <Dialog
        open={open}
        handler={handleOpen}
        dismiss={{ outsidePress: false, enabled: true, escapeKey: false }}
        className=" shadow-none"
        size={"xl"}
      >
        <DialogHeader className="flex justify-center gap-7 pt-2">
          DISCLAIMER
        </DialogHeader>
        <hr className="mt-2 mb-4 max-w-full" />
        <DialogBody className="text-justify">
          <Typography className="font-normal">
            As per the rules of the Bar Council of India, lawyers and law firms
            are not permitted to solicit their work or advertise in any manner.
            By clicking on the “I Agree” button, the user agrees and
            acknowledges as under:
          </Typography>
          <ul class="list-disc pl-9" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <li>
              There has been no advertisement, personal communication,
              solicitation, invitation or any other inducement of any sort
              whatsoever by or on behalf of Legal Inkwell or any of its members
              to solicit any work through this website.
            </li>
            <li>
              All material and information (except any statutory instruments or
              judicial precedents) on this website is the property of Legal
              Inkwell, and no part thereof shall be used, with or without
              adaptation, without the express prior written consent or approval
              from Legal Inkwell.
            </li>
            <li>
              The user(s) wishes to gain more information about Legal Inkwell
              for his/her/their own information and use.
            </li>
            <li>
              All information about Legal Inkwell on this website is being
              provided to the user(s) only on his/her/their specific request and
              any information obtained or materials downloaded from this website
              is completely at the volition of user(s); and any transmission,
              receipt or use of this site would not create any lawyer-client
              relationship.
            </li>
            <li>
              The information provided on this website is solely available at
              the request of the user(s) for informational purposes only. It
              should not be interpreted as a soliciting or advertisement.
            </li>
            <li>
              Legal Inkwell assumes no liability for the interpretation and/or
              use of the information available on this website, neither does it
              offer a warranty of any kind, nor express or implicit
              authorization of the content included in www.legalinkwell.com not
              even through a hyperlink, without express and written consent from
              www.legalinkwell.com.
            </li>
            <li>
              The content available on this website does not constitute, and
              shall not be construed, as legal advice or a substitute for legal
              advice.
            </li>
            <li>
              Legal Inkwell is not liable for any consequence of any action
              taken by the user(s) relying on material/information provided on
              this website or through any external links thereon.
            </li>
            <li>
              This website is a resource for informational purposes only and
              though intended, is not promised or guaranteed, to be complete or
              updated. Legal Inkwell does not warrant that the information
              contained on this website is accurate or complete, and hereby
              disclaims any and all liability to any person for any loss or
              damage caused by errors or omissions, whether such errors or
              omissions result from negligence, accident or any other cause.
            </li>
          </ul>
        </DialogBody>

        <hr className="mt-2 mb-4 max-w-full" />
        <DialogFooter className="flex justify-center gap-7 pt-2">
          <div>
            <Button
              variant="gradient"
              color="green"
              className=" shadow-none"
              onClick={handleOpen}
            >
              <span>I Agree</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>

      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/blog" component={Blog} />
          {/* <Route exact path="/pricing" component={Pricing} /> */}
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/civil" component={Civil} />
          <Route exact path="/criminal" component={Criminal} />
          <Route exact path="/banking-finance" component={BankingFinance} />
          <Route exact path="/Corporate" component={Corporate} />
          <Route exact path="/data-privacy" component={DataPrivacy} />
        
          <Route
            exact
            path="/intellectual-property-rights"
            component={IntellectualPropertyRights}
          />
          <Route
            exact
            path="/constitutional-law"
            component={ConstitutionalLaw}
          />
          <Route exact path="/service-law" component={ServiceLaw} />
          <Route
            exact
            path="/alternate-dispute-resolution"
            component={AlternateDisputeResolution}
          />
          <Route exact path="/CoreTeam" component={CoreTeam} />
          <Route exact path="/Newsltr" component={Newsltr} />
          <Route exact path="/Articles" component={Articles} />
          <Route exact path="/NFT" component={NFT}/>
          <Route exact path="/spacetech" component={spacetech}/>
          <Route exact path="/Genetic" component={Genetic}/>
          <Route exact path="/Whitecollar" component={Whitecollar}/>
          <Route exact path="/EnvironmentalLaw" component={EnvironmentalLaw}/>

        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default Pages;
